import React from 'react'
import Catlist from "../components/catlist"
import { setCaseSingleTag } from '../utils/processHtml';

import * as style from "./hero.module.css"


const HeaderText = ({ post, title: titleProp, pageOrPost = 'POST' }) => {

    const title = (titleProp || post.gatsbyCustomFields?.titleAsTwoParts || post.title).trim();

    // Handle '2-part' titles - i.e. where there's a main part and a sub- part
    const parts = title.split(/[{}]/)
        .map(part => setCaseSingleTag('h1', part.trim()))
        .filter(part => !!part)
    const smallPartIndex = title[0] === '{' ? 0 : 1;

    return (
        <div className={style.headerTextContainer}>
            {
                parts.map((part, i) => (
                    <h1
                        key={i}
                        className={i === smallPartIndex ? style.titleSmall : ''}
                    >
                        {part}
                        {i === 0 && <br />}
                    </h1>
                ))
            }


            {pageOrPost === 'POST' &&
                <div className={style.meta}>
                    <Catlist postObject={post} />
                    {" / "}
                    <span className="nowrap">
                        by {post.author.node.name  // ??? this needs to be a link, including tooltip
                        }
                    </span>
                    {" / "}
                    <span className="nowrap">
                        {new Date(post.date).toLocaleDateString("en-US", {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                        })}
                    </span>
                </div>
            }

        </div>

    )
}

export default HeaderText;