import React from "react"
import { Link } from "gatsby"

// import * as style from "./pagination.module.css"
import * as style from "./postNav.module.css"

const Pagination = ({ pageContext }) => {

  const { previousPagePath, nextPagePath } = pageContext
  return (
    <nav className={style.postnav}>
      {previousPagePath ? (
        <Link to={previousPagePath}>
          <div className={style.postnav__head}>← Newer Posts</div>
        </Link>
      )
        : <div />
      }

      {nextPagePath && (
        <Link to={nextPagePath}>
          <div className={style.postnav__head}>Older Posts →</div>
        </Link>
      )}
    </nav>


    //  <nav className={style.pagenav}>
    //       <div className={style.pagenav__item}>
    //       </div>

    //       <div className={style.pagenav__item}>
    //         {nextPagePath && <Link to={nextPagePath}>Older Posts →</Link>}
    //       </div>
    //     </nav>
  )
}

export default Pagination
