import React from 'react';

import HeaderText from './headerText';
import Wave from './wave';

import * as style from "./hero.module.css"


const WaveHeader = (props) => {

    return (
        <div className={style.waveHeaderContainer}>
            <Wave variation={10} />
            <div className={style.headerTextBackdrop}>
                <HeaderText {...props} />
            </div>
            <Wave invert variation={12} />
        </div>
    )
}

export default WaveHeader;