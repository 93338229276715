import React from "react"
import { Link } from "gatsby"

import * as style from "./catlist.module.css"

const Catlist = ({ postObject }) => {
    return (
        postObject.categories
            ? (
                <div className={style.article__catlist}>
                    {postObject.categories.nodes.map((category, index) => [
                        index > 0 && ", ",
                        <Link
                            key={index}
                            to={category.link}  // ??? probably wrong 
                        >
                            {category.name}
                        </Link>,
                    ])}
                </div>
            )
            : null
    )
}

export default Catlist
