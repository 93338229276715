import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/layout"

import WaveHeader from "../components/waveHeader"
import Pagination from "../components/pagination"
import PostsGrid from "../components/postsGrid"

import * as style from "./single.module.css"

// Hub = category index
// i.e. one hub per category

const HubTemplate = ({ data, pageContext }) => {
  const { catName } = pageContext
  const posts = data.allWpPost.nodes
  const page = data.thePage;
  // const ogImageUrl = page.seo.opengraphImage?.localFile.publicURL || page.gatsbyCustomFields?.heroImage?.localFile.publicURL;
  // const ogImageUrl = page.gatsbyCustomFields.heroImage?.mediaItemUrl;

  return (
    <Layout>
      <Seo
        title={`Category: ${catName}`}
        seo={page.seo}
      // ogImageUrl={ogImageUrl}
      />

      <WaveHeader
        post={page}
        pageOrPost='PAGE'
      />

      <article className={`content-container`}>

        <div className={`${style.article__content} ${style.hubText}`}>
          <div dangerouslySetInnerHTML={{ __html: page.content }} />
        </div>

      </article>

      <div className='main-container'>

        <section>
          <PostsGrid
            posts={posts}
          />
        </section>

        <Pagination pageContext={pageContext} />

      </div>

    </Layout>
  )
}

export const pageQuery = graphql`
fragment seoHubData on WpPage {
  seo {
    title
    metaDesc
    opengraphImage {
      localFile {
        publicURL
      }
    }
  }
}
  query($pageId: Int!, $catId: Int!, $skip: Int!, $limit: Int!)
  {
    thePage: wpPage(databaseId: {eq: $pageId}) {
      title
      slug
      content
      ...seoHubData
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1360) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allWpPost(sort: {fields: date, order: DESC}, skip: $skip, limit: $limit, filter: {categories: {nodes: {elemMatch: {databaseId: {eq: $catId}}}}}) {
      nodes {
        date
        databaseId
        excerpt
        uri
        slug
        title
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fixed(height: 300) {   # ??? need this?
                  ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 500, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
  `

export default HubTemplate

